// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}
// .c-sidebar-nav{
//   background-color:#4868dc;
//   color:"#fff"
// }
// .c-sidebar .c-sidebar-nav-link.c-active{
//   background-color:#6b8bff ;
 
// }
// .c-sidebar .c-sidebar-nav-link:hover{
//   background-color:#6b8bff ;
 
// }