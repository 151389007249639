@font-face {
  font-family: "Orbitron";
  src: local("Orbitron"),
   url("./fonts/Orbitron-Regular.ttf") format("truetype");
  // font-weight: bold;
  }
/* cyrillic-ext */
// @font-face {
//   font-family: 'Oswald';
//   font-style: normal;
//   font-weight: 400;
//   src: url(fonts/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUtiZTaR.woff2) format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Oswald';
//   font-style: normal;
//   font-weight: 400;
//   src: url(fonts/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUJiZTaR.woff2) format('woff2');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Oswald';
//   font-style: normal;
//   font-weight: 400;
//   src: url(fonts/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUliZTaR.woff2) format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Oswald';
//   font-style: normal;
//   font-weight: 400;
//   src: url(fonts/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUhiZTaR.woff2) format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Oswald';
//   font-style: normal;
//   font-weight: 400;
//   src: url(fonts/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUZiZQ.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
.p-14 {
  padding: 14px;;
}

a {
  color: #000;
}
.hexagon-shape {
  width: 175px;
  height: 200px;
  background-color: #fff;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.denim-color {
  background-color: #313c52 !important;
}

.slidein {
  animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.intro-block {
  position: relative;
  display: block;
  align-items: center;
  height: 100%;

  //overflow: scroll;
}

.bg-img {
}

body {
  // font-family: oswald;
  // font-family: 'RobotoRegular', 'Helvetica Neue', Helvetica, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  background: #f6f6f6;
  color: rgb(60, 75, 100);
}
.menubar-icon {
  height: 20px;
  height: 25px;
  margin-left: 15px;
  margin-top: 20px;
  cursor: pointer;
}
.mfe-2 {
  color: rgb(60, 75, 100);
  padding: 5px;
}
.PageHeading {
  text-align: center;
  padding-top: 20px;
  font-size: 30px;
}

table thead {
  color: #2658a1;
}

.heading {
  font-size: 20px;
  color: #295485;
  margin-top: -10px;
  margin-bottom: 5px;
  text-decoration: underline !important;
}
.btn-custom {
  color: #fff;
  background-color: #2658a1;
  border-color: #6c757d;
  order: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-custom:hover {
  color: #fff;
}

/*************** DASHBOARD PAGE OPEN************/

.LogoAnimation {
  animation: bounce 1s;
  animation-direction: alternate;
  /*animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);*/
  /*animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);*/
  animation-iteration-count: 4;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50px);
  }
}

.CategoryContent {
  font-size: 16px;
  color: #000;
  text-align: center;
}
.CategoryContent a {
  color: #000;
}
.CategoryContent img {
  padding-top: 20px;
}

section.intro {
  // font-family: oswald;
  // font-family: 'RobotoRegular', 'Helvetica Neue', Helvetica, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  font-weight: 500;
  // position: relative;
  // height: 100vh;
  width: 100%;
  // background: #f6f6f6;
  // overflow-x: scroll;
  //   overflow-y: hidden;
  // z-index: 1;
}

/********************************************************************************************************************************
                      Hexagon Intro Blocks
********************************************************************************************************************************/
.intro-block {
  position: relative;
  align-items: center;
  height: 100%;
  // box-shadow: inset 0px 0px 64px 0px rgba(50,50,50,0.75);
}

.hex-bg-big-text {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.centerfold-wrap {
  position: relative;
  // display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.hex-master-wrap {
  position: fixed;
  width: 100%;
  min-width: 665px;
  display: flex;
  flex-wrap: wrap;
}

.border-1,
.border-2,
.border-3 {
  position: absolute;
  width: 100%;
  height: 88px;
  top: 46px;
  left: 0;
  border-radius: 8px;
  z-index: 2;
  border-color: #333;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
}

.border-2 {
  transform: rotate(60deg);
}

.border-3 {
  transform: rotate(120deg);
}

.border-3::before {
  display: none;
  position: absolute;
  content: "";
  bottom: 10%;
  left: 42px;
  width: 50%;
  height: 83%;
  background-color: rgba(255, 255, 255, 0);
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: white;
  border-radius: 50%;
  visibility: visible;
  z-index: -1;
  /* transform: rotate(60deg); */
}

.intro-block .grid-1,
.intro-block .grid-2,
.intro-block .grid-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin: 0 auto;
  margin-bottom: -50px;
}

.intro-block .grid-3 {
  //  margin-bottom: -50px;
}

.intro-block .grid-2,
.intro-block .grid-3 {
  // margin-top: -50px;
}

.hex-wrap {
  opacity: 1;
  display: block;
  position: relative;
  margin: 25px 20px 0 6px;
  transform: scale(1.1);
}

.hex-wrap .label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 58px;
  color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.hex-wrap .label svg,
.hex-wrap .label img {
  height: 90px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hexagon {
  width: 300px;
  height: 150px;
  margin: 15px 0 0 -75px;
  overflow: hidden;
  visibility: hidden;
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(90deg);
  /*cursor: pointer;*/
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hex-inner-1 {
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.hex-inner-2 {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  visibility: visible;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
  overflow: hidden;
}

.hex-inner-2::before,
.hex-inner-2::after {
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  top: 0;
  left: 0;
  height: 110%;
  width: 110%;
  content: "";
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

/**********************************************************************************
                Hex Hover Area
**********************************************************************************/
.hex-init {
  position: absolute;
  top: 46px;
  left: 0px;
  height: 46%;
  width: 100%;
  z-index: 9;
  transform: rotate(-60deg);
  /*cursor: pointer;*/
}

.hex-init::after {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  transform: rotate(-60deg);
}

.hex-init::before {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  transform: rotate(60deg);
}

/*************** DASHBOARD PAGE OPEN************/

/*************** CATEGORY PAGE OPEN************/

/*
.overview {
  font-family: oswald;
  background: #f6f6f6;
  box-shadow: inset 0px 0px 64px 0px rgb(50 50 50 / 75%);
  height: 100%;
}
*/
.overview button {
  font-size: 15px;
}
.headingTable {
  text-align: center;
}
/*************** CATEGORY PAGE CLOSED************/

/*************** SUB CATEGORY PAGE OPEN************/

.row.headingTable {
  padding-top: 20px;
}

/*************** SUB CATEGORY PAGE CLOSED************/

/*************** DASHBOARD SUMMARY PAGE OPEN************/
.summary-col1 {
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 18px;
  font-weight: 600;
  color: #2658a1;
}
.summary-col2 {
  font-size: 16px;
  font-weight: 500;
  color: #2658a1;
}
.summary-col3 {
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 16px;
  font-weight: 500;
  color: #2658a1;
}

.summary-row-strip .row:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.summary-blue {
  border-top: 1px solid #2658a1;
}
/*************** DASHBOARD SUMMARY PAGE CLOSE************/
// .mainbody-dashboard-1 {
//    background-image: url(../assets/background1.jpg);
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-attachment: fixed;
//   background-position: center;
// }

.mainbody {
  width: 100%;
  border-radius: 0px;
  background-color: #222326;
  background-color: #fff;
  // border-top-left-radius: 20px;
  // border-bottom-left-radius: 20px;
  // box-shadow: inset 0px 0px 64px 0px #323232;
}

.c-sidebar {
  box-shadow: none !important;
  border-radius: 0px;
}

.flowImage {
  max-width: 100%;
  margin-bottom: 20px;
}

section {
  // padding-left: 15px;
  // padding-right: 15px;
}
.font10 {
  font-size: 12px;
  text-align: left;
}

.c-sidebar-minimized img {
  display: none !important;
}

// .foot{
//   position: sticky !important;
//     bottom: 0 !important;
// }

// ******************* Sub Department *************** //
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.WidgetValue {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 14px;
}
.WidgetCat {
  font-size: 13px;
}
.borderRight {
  border-right:1px dotted;
}

.borderBottom {
  border-bottom:1px dotted;
}
// .border
// {
//   border:1px solid
// }
.widgetRow:hover {
  border: 1px solid;
}
.subDeptWidget {
  // margin-right: 15px;
  box-shadow: 5px 5px #e8e8e8;
  border-radius: 8px;
  // min-width: 200px;
}

.subDeptWidget .h4 {
  width: auto;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.8em;
  background: #295485;
  padding: 5px;
  min-width: 170px;
  margin-bottom: 5px;
}

.subDeptWidget:hover {
  // background-color: #295485;
  // color: #fff !important;
}
.subDeptWidget:hover a {
  // color: #fff;
}
.category h1 {
  // background: rgb(60, 75, 100);
  background: #313c52;
  padding: 15px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
}
.WidgetText {
  font-size: 12px;
  text-align: center;
}
.WidgetText span {
  font-size: 17px;
}

@media only screen and (max-width: 600px) {
  .intro-block .grid-1,
  .intro-block .grid-2,
  .intro-block .grid-3 {
    margin-left: 0;
  }

  .intro-block {
    height: auto;
  }
}

.widgetbig {
  background: #ccc;
  border: 1px #295485 solid;
  color: #fff;
  font-size: 100%;
  text-indent: 5%;
  line-height: 180%;
  width: 100%;
  text-transform: uppercase;
}

.widgetbig .text-dark {
  color: #000 !important;
  text-align: center;
}

.widgetbig .w-25 {
  // background: #313c52 !important;
  background: #343a40 !important;
  //min-width: 80px !important;
  text-align: center;
}
.widgetbig .parTitle {
  width: 50% !important;
}
.widgetbig .w-20 {
  // background: #313c52 !important;
  background: #343a40 !important;
  //width: 60px !important;
  text-align: center;
}
.widgetheader {
  // background: #313c52;
  background: #295486;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.widgetbig {
  background: #ccc;
  border: 1px #295485 solid;
  color: #fff;
  font-size: 100%;
  text-indent: 2%;
  line-height: 180%;
  width: 100%;
}
.widgetheader {
  background: #295485;
  display: flex;
  flex: 1;
  justify-content: space-between;
  text-align: left;
  align-items: center;
}

.badge-successs {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #00ff00;
  margin-right: 10px;
}
.badge-disabled {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #d2d2d2;
  margin-right: 10px;
}

.badge-dangerr {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: red;
  margin-right: 10px;
}
.foot-center {
  padding-left: 10px;
}

.col-3-5 {
  flex: 0 0 32%;
  max-width: 32%;
}

.searchbox  {
  // position: absolute;
  padding: 5px;
  z-index: 999999;
  background-color: #ccc;

}


.searchbox button {
  margin-top: 4px !important;
  width: 88px;
  height: 40px;
  float: right;
  background-color: #313c52;
}

.searchbox button:hover {
  background-color: #222326;
}

.searchbox label {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -4px;
}

.searchbox .sBtn {
  background-color: #fff !important;
}

.typebox  {
  position: absolute;
  padding: 5px;
  z-index: 999999;
  background-color: #ccc;
  top: 188px;
}
.typebox input,.searchbox input  {
  margin-right: 5px;
}

.typebox h5,.searchbox h5  {
  text-align: center;
  text-decoration: underline;
}

.tab-content {
  background-color: #f9fafa!important;
}

.card-text {
  padding-left: 6px;
  font-size: 12px;
} 
.cardCat {
    background-color: #295485;
    color: #fff !important;
}
.card-body .me-3 {
  padding: 24px 12px;
}

.WidgetHeader {
  // border: 1px solid;
}
.text-12{
  font-size: 11px !important;
}

.categoryBox  {
  border: 1px solid;
  border-color: #d8dbe0;
  box-shadow: 5px 5px #e8e8e8;
  border-radius: 0.25rem;
}

.categoryBox h4 {
  //color: #295485;
  text-align: center;
  padding-top: 18px;
}

.categoryBox label {
  border: 1px solid;
  padding: 5px;
  background-color: #295485;
  color: #fff;
}


.v2-catPage {
  box-shadow: none!important;
}

.v2-CatBox {
  background-color: #295485;
  padding: 5px;
}

.v2-Guage {
  margin-top: 10px;
  margin-bottom: 10px;
}

.v2-Guage img {
  width: 200px;
}

.v2-CatHeading {
  border: 1px solid;
  border-radius: 5px;
  color: #fff;
  font-size: 29px;
}

.v2-CatHeading a {
  color: #fff !important;
}

.v2-SecHeading {
  border: 1px solid;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
}

.v2-SecHeading a {
  color: #fff !important;
}

.v2-SubCatBox {
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  background-color: #fff;
}

.v2-SubCatHeading {
  padding: 8px;
  border: 1px solid;
  border-radius: 5px;
  background-color: #295485;
  color: #fff;
}

.v2-SubCatHeading a {
  color: #fff !important;
}

.v2-CatTotalizer,
.v2-CatConsmp {
  border: 1px solid;
  border-radius: 5px;
  color: #fff;
  background-color: #295485;
  font-size: 20px;
  font-family: 'Orbitron', sans-serif;
  width: 100%;
  font-weight: 1000;
  letter-spacing: 2px;
}

.v2-subHeading {
  background: #313c52;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  font-size: 20px;
  padding: 5px;
}

.v2-bg-theme {
  background-color: #295485;
}

.v2-widgetbig {
  background: #ccc;
  border: 1px #295485 solid;
  color: #fff;
  font-size: 100%;
  text-indent: 2%;
  line-height: 180%;
  width: 100%;
}

.v2-bgWidget {
  background: #343a40 !important;
  text-align: center;
}

.v2-widgetvalue {
  color: #000;
  font-size: 15px;
  font-family: 'Orbitron', sans-serif;
  width: 100%;
  font-weight: 1000;
  letter-spacing: 2px;
  text-align: center;
  background-color: #fff;
}



legend{width:auto;text-align: center; text-transform: uppercase;}
.data-title{
    width: 30%;
    text-transform: uppercase;
}
.data-unit{
    padding-left: 5px;
    width: 20%;
    text-transform: uppercase;
}
.data-value1{
    background-color: #fafa9f;
    border: 0.5px solid #000;
    text-align: center;
    border-collapse: collapse;
    width: 15%;
}
.data-value2 {
    background-color: #000000cc;
    color: #fff;
    text-align: center;
    border-collapse: collapse;
    border: 0.5px solid #6c757d;
    width: 15%;                
}
.data-value3 {
    background-color: #eee;
    color: #000;
    text-align: center;
    border-collapse: collapse;
    border: 0.5px solid #000;
    width: 15%;                
}
.text-black{
  color: #000;
}


.v3-catPage {
  box-shadow: none!important;
}

.v3-catBox legend {
  width: auto;
  text-align: center;
  text-transform: uppercase;
  color:#fff;
  font-size: 2em;
  background: #295485;
  padding: 5px;
  min-width: 170px;
}

.v3-catBox {
  padding: 20px;
}

.v3-catBox h1 {
  color: #295485;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 1;
  /* margin: 40px auto 0 0; */
  background-color: transparent;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  
}


.v3-dottedSeparator {
  border-top: 1px dashed #359756;
}
.v3-catBox h5 {
  color: #295485;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1;
  /* margin: 40px auto 0 0; */
  background-color: transparent;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
}

.v3-catBox span {
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.v3-container-layout {
  padding: 20px 30px;
}

.v3-container-layout h2 {
  font-weight: 700;
  margin: 34px 0 0;
  line-height: 1.2;
  font-size: 2.25rem;
}

.v3-dataHeight {
  // height: 28em;
  height: 70vh;
  overflow:auto;
}

.text-ellipese{
  white-space: nowrap; 
  // width:50px;
  // width: 50%; 
  width: 160px;
  // width: 60%;
  overflow: hidden;
  display:inline-block;
  text-overflow: ellipsis;
  // border: 1px solid #000000;
}

.v3-labelBox {
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 125px;
  border: 5px solid #ccc;
  margin-right: 10px;
  font-size: 1.3em;
}

a:hover {
  text-decoration: none;
}
.cursor-pointer:hover{
  cursor: pointer;
}

.card0 {
  box-shadow: 0px 4px 8px 0px #757575;
  border-radius: 0px;
}

.card2 {
  margin: 0px 40px;
}

.Loginlogo {
  width: 105px;
  height: 98px;
  margin-top: 20px;
  margin-left: 35px;
}

.image {
  width: 360px;
  height: 280px;
}

.bg-blue {
  color: #fff;
  background-color: #295485;
}
.loginPage input, textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2C3E50;
  font-size: 14px;
  letter-spacing: 1px;
}
.loginPage input:focus, textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #295485;
  outline-width: 0;
}

.loginPage ::placeholder {
  color: #BDBDBD;
  opacity: 1;
  font-weight: 300
}

.loginPage :-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 300
}

.loginPage ::-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 300
}

.loginPage a {
  color:#fff;
}

.pass_show{position: relative} 

.pass_show .ptxt { 

position: absolute; 

top: 50%; 

right: 10px; 

z-index: 1; 

color: #f36c01; 

margin-top: -10px; 

cursor: pointer; 

transition: .3s ease all; 

} 

.pass_show .ptxt:hover{color: #333333;} 

.avatar-img {
  width: 100%;
    height: 100%;
    object-fit: cover;
}
.avatar-xxl {
  font-size: 2.66667rem;
  width: 8rem;
  height: 8rem;
}

.formHr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 73%;
  margin-left: 11px;
}

.guages {
  width: 100%;
  height: 100%;
  
}

input[type="password"], select {
  // color: #eeeeee !important;
  // border-color: #d8dbe0 !important;

  background-color: #fff !important;
  background-image: none !important;
  display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: middle;
    border: 1px solid;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    appearance: none;
    color: #768192;
    border-color: #d8dbe0;
}

// @media only screen and (min-width: 600px) and (max-width: 1200px) {
//   .guages {
//     width: 200px;
//   }

// }
// @media only screen and (min-width: 1800px){
//   .guages {
//     width: 400px;
//   }

// }

.accordion {
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 1px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
  margin-top: 0px;
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110 ,253 , 0.25);
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
}
.accordion-body {
  padding: 1rem 1.25rem;
}
.accordion-button:hover {
  cursor: pointer;
  color: #000;
}
.table-text{
  font-size: 11px;
}
.renderunit{
  position: absolute;
  bottom: 15%;
  left:49%
}

.canvasjs-chart-credit {
  display: none !important;
}


.loader {
  height: 100vh;
  background: rgba(246, 248, 255, 0.5);
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #295485;
  flex-direction: column;

}

.ant-space-item {
  margin: 0 auto;
}

.my-4{
  width: 100%;
  // background-color: red;
}
.nav{
  margin:10px 0px
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: rgb(49, 60, 82)!important;
}
.nav-fill .nav-item {
cursor: pointer;
}
.empty-state{
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    height: 90vh;
}
.empty-step{
  padding: 10px 0;
  margin-top: 20px;
}
.empty-step-heading{
  font-size: 18px;
  color:#000
}
.empty-step-content{
  font-size: 16px;
  color:#212529
}
.empty-step-content:hover{
  font-size: 16px;
  color:#2658a1
}
.empty-step-border{
 border-radius: 6px;
  border-color: #295485 !important;
  border-width: 8px !important;
  border-style: solid;
}

.empty-step-icon{
  display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.empty-step-parent-heading{
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
.bg-disabled{
  background-color: '#d2d2d2'!important;
}
.display-7{
  font-size: 0.75rem!important;
}