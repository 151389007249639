/*================================
	Color Variation
=================================*/

.logo {
  height: 100px !important;
  width: 100px !important;
}

.dark-text {
  color: #222 !important;
}
.swiper-pagination-bullet-active {
  color: #fff !important;
}
.swiper-pagination-bullet {
  color: #fff !important;
}
.about-heading {
  text-decoration-line: underline;
}

.black {
  color: #000;
}

.App {
  // min-height: 100vh;
  // background-image: url("../assets/background1.jpg");
  // background-size: 100% 100%;
  // padding-left: 64px;
}

.header {
  display: block;
  width: 50%;
  font-size: 60px;
  font-style: normal;
  line-height: 120px;
  color: #ffffff;
  padding-top: 54px;
  /*padding-left: 36px;*/
  padding-bottom: 32px;
}

.sign-label {
  color: #ffffff;
  font-size: 30px;
  /*padding-left: 36px;*/
}

.input-block {
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  width: 350px;
}

.login-input {
  background-color: #fff !important;
  background-image: none !important;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
  color: #768192;
  border-color: #d8dbe0;
}

input[type="password"],
select {
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 24px;
  height: 40px;
  border: 2px solid white;
  border-radius: 6px;
  background: transparent !important;
  outline: none;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  width: 100%;
}

input::placeholder {
  color: #768192 !important;
}

.input-block-user,
.input-block-pass {
  position: relative;
}

.input-user {
  position: absolute;
  right: 8px;
  top: 2px;
  width: 32px;
  height: 32px;
  color: white;
  // background-image: url("../../assets/email.png");
  background-image: url("../assets/email.png");
  background-repeat: no-repeat;
  background-position: center;
}

.input-pass {
  position: absolute;
  right: 8px;
  top: 2px;
  width: 32px;
  height: 32px;
  color: white;
  background-image: url("../assets/Group.png");
  background-repeat: no-repeat;
  background-position: center;
}

button {
  width: 144px;
  height: 50px;
  margin-top: 36px;
  background-color: #fd6262;
  border: 1px solid #fd6262;
  box-sizing: border-box;
  border-radius: 4px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}
.c-header {
  background-color: "transparent" !important;
  height: 60px !important;
}

.c-header-toggler-icon {
  margin-top: -40px !important;
}
.c-header-toggler .d-md-down-none {
  display: none !important;
}
.table {
  // color: #fff;
}
.table th,
.table td {
  border-top: 0px solid;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.btn-login {
  margin-right: 6px;
  background-color: rgb(255, 255, 255) !important;
  // color: rgb(193, 31, 29);
  color: #000 !important;
  max-width: 100px;
  padding: 2px !important;
  height: 40px;
  position: absolute;
  right: 107px;
  top: 2px;
}
.plr--180 {
  padding: 0 80px;
}
.bk-separator--120 {
  height: 40px;
}
.testimonial-style--3 .quote {
  margin: 0 auto 20px;
  font-size: 28px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border: 2px solid #c11f1d;
  border-radius: 50%;
  text-align: center;
}
.serviceblock {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.booking-form {
  top: 120px;
  /* height: 500px; */
  margin: auto;
  // background: black;
  padding: 20px;
  margin-bottom: 150px;
  // background-color: red;
}
.evaluation-form-dropdown {
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 24px;
  height: 40px;
  border: 1px solid #000 !important;
  border-radius: 6px;
  background: transparent !important;
  outline: none;
  font-size: 14px;
  color: #000 !important;
  cursor: pointer;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #000;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.radia-btn-parent {
  display: inline-flex !important;
}

.test {
  border: 2px solid green;
  width: 24px;
  height: 24px;
  //margin: 0 8px;
  // background-image: url("../assets/icons/transfer.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.c-switch {
  height: 28px;
}

.c-switch-slider {
  width: 60px !important;
  padding: 4px !important;
  border: 2px solid #8a8a8a !important;
  border-radius: 25px !important;
}

.c-switch-input:checked + .c-switch-slider {
  background-color: #ffffff !important;
}

.c-switch-input:checked + .c-switch-slider::before {
  border-radius: 50%;
  background-color: #249e6b !important;
  border: 1px solid #249e6b !important;
}

.c-switch-input:checked ~ .c-switch-slider::before {
  transform: translateX(32px);
}

.c-switch-slider::before {
  border-radius: 50%;
  background-color: #8a8a8a;
}

.form {
  background-color: transparent !important;
  border: 2px solid white;
  color: white;
}
.check-toggle {
  width: 200px;
  display: flex;
  justify-content: space-around;
  padding: 8px;
  align-items: center;
}

.c-sidebar {
  box-shadow: 2px 6px 15px 2px rgba(12, 10, 11, 0.8);
  border-radius: 12px;
}

.c-sidebar-minimized {
  .slid {
    font-size: 0 !important;
    border-radius: unset !important;
    // background-color: rgb(27, 29, 33) !important;
    background-color: #3c4b64 !important;
    box-shadow: none !important;
  }

  .c-sidebar .c-sidebar-nav-link.c-active {
   // border-radius: 8px !important;
    margin: 0 2px !important;
    color: #fd6262 !important;
  }
}

select.form-control option {
  background-color: #8a8a8a !important;
}

.form-control:focus {
  color: #8a8a8a !important;
  font-size: 16px !important;

  option {
    color: white;
    cursor: pointer;
  }

  option:hover {
    background-color: #1b9e3e !important;
  }
}

.c-sidebar .c-sidebar-nav-link.c-active {
 // border-radius: 8px !important;
  //margin: 0 8px !important;
  color: #fff !important;
}

.c-sidebar-nav-link:hover {
  background-color: #222326 !important;
 // border-radius: 8px;
 // margin: 0 8px;
  color: #fff !important;

  .c-sidebar-nav-icon {
    color:#fff !important;
  }
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon {
  color: #fff !important;
}
.c-sidebar-nav{
  background-color: #313c52;
}
.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: #fff !important;
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon {
  color: #fff !important;
}

.project-icon {
  width: 64px;
  height: 64px;
  //margin: 0 8px;
  // background-image: url("../assets/layer.png");
  background-repeat: no-repeat;
  background-position: center;
}

.click-project {
  //border-bottom: 4px solid #8A8A8A;
  color: #fd6262 !important;
  font-weight: bold;
}

.project-label {
  color: #fd6262;
}

.c-sidebar .c-sidebar-minimized {
  .test-chart {
    border: 1px solid red;
  }
}


.c-sidebar {
  #test-chart {
    border: 2px solid green;
  }
}

.section {
  width: 90%;
  margin: 0 auto;
  // color: #f2f2f2 !important;
}
.main-section {
  margin: 0 30%;
}
.inspection-section {
  width: 90%;
  margin: 0 auto;
}
.dealers-section {
  width: 90%;
  margin: 0 auto;
}
.car-section {
  width: 90%;
  margin: 0 auto;
}
.active-inactive-padding {
  padding: 0;
}
.modal-body-padding {
  padding: 50px;
}
.read-field {
  text-align: center;
  padding: 20px;
}
.auction-filter {
  height: 120px;
  padding: 20px;
  padding-right: 40px;
}
.charts {
  height: 300px;
  margin-top: 40px;
}
.dealers-button {
  padding: 0;
}
.table {
  // background-color: grey;
}
.radio-size {
  width: 40px;
}
.sidebar-minimizer {
  width: 50px;
  position: absolute;
  right: 3px;
  // top: 66px;
  top: -32px;
  position: absolute !important;
  bottom: unset;
}
.modal-body-padding {
  padding: 0;
}
.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 20px;
}
.modal-date {
  margin-bottom: 50px;
  margin-top: 30px;
  margin-left: 20px;
}
.dropdown-border {
  border: 3px solid white;
}
.container-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 5px;
  margin-top: -45px
}
select option {
  margin: 40px;
  color: black;
  background-color: white !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

select {
  background-color: #eeebdd !important;
  border: none;
  color: #ac0d0d;
}
.email-header {
  display: none;
}
.header1{
  display: none;
}
@media only screen and (max-width: 991px) {
  .header1{
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .email-header {
    display: block;
  }
  .charts {
    height: 200px;
    margin-top: 20px;
  }
  .request-button {
    width: 100%;
  }
  .dealers-button {
    padding: 0;
    font-size: 12px;
  }
  .radio-size {
    width: 30px;
  }

  .input-block {
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    width: 250px;
  }
  .header1{
    display: block;
  }
}
.read-only-view {
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  margin: 8px;
  cursor: none;
}

body {
  background-color: #fff;
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
}

h3{
  color:rgb(60, 75, 100);
}
.main {
  margin: 0px auto;
  padding: 0px 0;
  width: 100%;
  display: table;
}

.centerContainer {
  width: 1000px;
  margin: 0 auto;
}

.heading1 {
  background: #2390e8;
  padding: 10px 0px;
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-family: sans-serif;
  margin: 0px;
}
.box {
  border: 1px solid #444;
}
.bor1 {
  display: flex;
}

.yellow {
  background-color: #fee599;
}

.textContent {
  text-align: center;
  padding: 5px 10px;
}

.textContent dd {
  margin: 0px;
  padding: 0px;
}

.btnColor {
  background: "#fff";
  /* background: linear-gradient(90deg, rgba(96,117,149,1) 0%, rgba(85,130,155,1) 35%, rgba(96,117,149,1) 100%);      */
}

.borBtn {
  border: 1px solid #afafaf;
  padding: 0 15px;
  height: 40px;
  color: #444;
  position: relative;
  border-radius: 5px;
}

.borBtpos {
  top: -89px;
  position: relative;
}

.bore2 {
  position: relative;
  top: -85px;
}
.arrow2 {
  position: relative;
  top: 30px;
}

.arrow1 {
  position: relative;
  top: 21px;
}

.arrowbr2 {
  position: relative;
  top: 25px;
}
.boxpos {
  position: relative;
  top: 57px;
}

.btnpos {
  position: relative;
  top: 75px;
}

.leftSection {
  width: 330px;
  float: left;
}

.rightSection {
  float: left;
}

.tops {
  top: 38px;
  left: -116px;
}

.arrow4 {
  position: relative;
  top: 78px;
  left: -100px;
}

.waterTank {
  display: flex;
}

.boxpos3 {
  position: relative;
  top: 65px;
}

.arrow101 {
  position: relative;
  top: 72px;
  left: -100px;
}

.arrow1021 {
  position: relative;
  top: 72px;
  left: -130px;
}

.rows {
  display: flex;
  margin-top: 40px;
}

.cols {
  margin: 0 10px;
  max-width: 33.33%;
  flex: auto;
}

.btnAutoH {
  font-size: 25px;
  width: 200px;
}

.h290 {
  height: 390px;
}

.h200 {
  height: 219px;
}

.h90 {
  height: 290px;
}
.arrowImage {
  width: 100px;
}

.arrowImage img {
  margin: 40px 0;
}

.arrowImage img:nth-child(3) {
  position: relative;
  top: 7px;
}

.arrowImage img:nth-child(4) {
  position: relative;
  top: 10px;
}

.h80 {
  height: 80px;
}

.pos110 {
  position: relative;
  top: 110px;
}

.pos80 {
  position: relative;
  top: 80px;
}

.pos140 {
  position: relative;
  top: 140px;
}

.fourBox {
  width: 80px;
}

.h70 {
  height: 70px;
}
.h80 {
  height: 80px;
}

.ProcessConet {
  padding-top: 0px;
  width: 900px;
  margin: 0 auto;
}

.mr15 {
  margin-bottom: 15px;
}

.green {
  background: #aad05f !important;
}

.greenBox {
  width: 80px;
  height: 80px;
  margin: 40px 10px;
}

.arrowUp img {
  transform: rotate(-90deg);
}

.arrowUp {
  position: relative;
  top: 37px;
  left: 66px;
}

.arrowUps {
  position: relative;
  top: 59px;
  left: 112px;
  width: 80px;
}

.marginLR {
  margin: 55px 48px;
}
.bottomBox {
  position: relative;
  left: 115px;
}

.bottomBox1 {
  position: relative;
  left: 155px;
  top: 170px;
}

.heading3 {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-bottom: 12px;
  color: #7d7d7d;
}

.pos150 {
  position: relative;
  top: 70px;
}

.pos182 {
  position: relative;
  top: 97px;
}

.mr55 {
  margin-top: 55px;
}

.arrowimage2 img:nth-child(2) {
  position: relative;
  top: 20px;
}

.top40 {
  top: 47px;
}

.middleBox {
  width: 256px;
  float: left;
  position: relative;
}

.middleBox::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 87.5%;
  background-color: #000;
  top: 32px;
}

.flexBox {
  float: left;
  width: 400px;
}

.buttonContent {
  display: flex;
  margin-bottom: 70px;
}

.middleBox .bor1 {
  margin-bottom: 84px;
  position: relative;
}

.topmn10 {
  top: -10px;
}

.topmn18 {
  top: -18px;
}

.topmn28 {
  top: -28px;
}

.w160 {
  width: 160px;
}

.boxes {
  width: 80px;
  height: 80px;
  position: relative;
}

.endBox {
  float: left;
}

.enBox {
  position: relative;
}

.diverImg {
  position: absolute;
}

.diver1 {
  right: -90px;
  top: 80px;
}

.boxPos1 {
  left: 172px;
  top: 86px;
}

.top68 {
  top: 73px;
}

.top135 {
  top: 146px;
}

.top204 {
  top: 219px;
}

.top270 {
  top: 292px;
}

.leftSectionbox {
  float: left;
  position: relative;
  top: 530px;
}

.widget {
  width: 140px;
  background: #2f2e2e;
  border-radius: 5px;
  height: 83px;
}

.widget .Wheader {
  width: 100%;
  background: #1295d3;
  border-radius: 5px 5px 0 0;
  height: 27px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
  text-indent: 15px;
  display: flex;
  justify-content: space-between;
}

.widget .badge-dangerrs {
  width: 20px;
  height: 20px;
  background: #ff0c24;
  border-radius: 10px;

  margin-top: 3px;
}
.widget .badge-succes {
  width: 20px;
  height: 20px;
  background: #155724;
  margin-left: 10px;
  border-radius: 10px;

  margin-top: 3px;
}

.badge-disable {
  opacity: 0.6;
}

.fields {
  display: flex;
}

.fields span {
  padding: 5px 1px;
  margin: 2px 2.2px;
  font-size: 12px;
}
.spaned {
  background: #536b7c;
  color: #fff;
  width: 50px;
  text-indent: 2px;
}

.valueSpan {
  width: 50px;
  text-indent: 2px;
  background: #b1b1b2;
  color: #5e5e5e;
}

.valueKHP {
  background: #000;
  color: #fff;
  text-align: center;
  width: 28px;
  font-size: 10px !important;
}

.po196 {
  position: relative;
  top: -196px;
}

.w89 {
  width: 89px;
}

.widPos3 {
  position: relative;
  left: -100px;
  top: 25px;
}

.widPos33 {
  position: relative;
  left: -130px;
  top: 25px;
}

.tankImh {
  width: 170px;
  position: relative;
}

.tankImh > img {
  width: 100%;
}

.ROPlant {
  width: 120px;
  position: relative;
}

.ROPlant > img {
  width: 100%;
}

.allLabel {
  white-space: nowrap;
  padding: 15px 0;
  font-weight: bold;
  position: absolute;
  transform: rotate(-90deg);
  left: -37px;
  top: 89px;
}

.waterlabel {
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 44%;
  font-size: 20px;
  color: #565656;
}

.ROplantlabel {
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #444;
  display: block;
}

.PHCW {
  width: 100%;
}

.PHCW .fields span.spaned,
.PHCW .fields span.valueSpan {
  width: 40%;
}

.PHCW .fields span.valueKHP {
  width: 18%;
}

.marginBottom0 {
  margin-bottom: 0px;
}

/*Flex box scroll Start*/
.flexboxs {
  display: flex;
  width: 75%;
  overflow-x: scroll;
  height: 480px;
  padding-top: 90px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(2, 2, 2, 0.555);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  height: 6px;

  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #1295d3;
}

.hotTank,
.colTank {
  width: 110px;
  position: relative;
  left: -18px;
}

.colTank {
  left: 0px;
  margin-left: 5px;
}

.hotTank > img,
.colTank > img,
.thank > img {
  width: 100%;
  position: relative;
  top: 130px;
}
/*Flex box scroll end*/

.posRel {
  position: relative;
}

.coolingfan {
  position: absolute;
  left: 217px;
}

.top180 {
  top: 180px;
}

.top164 {
  top: 164px;
}

.arrowImages::before {
  position: absolute;
  left: 30px;
  background: #000;
  width: 2px;
  height: 193px;
  content: "";
  top: 44px;
}

.posRel29 {
  position: relative;
  left: 29px;
}

.wt40 {
  width: 30px;
  left: 60px;
}

.process {
  position: absolute;
  right: 10px;
  top: 177px;
}

.boxPro1 {
  background: #1295d3;
  width: 118px;
  margin: 10px;
  padding: 13px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  margin-bottom: 56px;
}

.allProsArrow {
  position: absolute;
  top: 111px;
  left: 150px;
}

.cruAr {
  position: absolute;
  right: 186px;
  top: 58px;
  height: 163px;
}

.cruAr > img {
  height: 100%;
}
.collingBlowDown {
  position: absolute;
  width: 80px;
  right: 294px;
  bottom: -107px;
}

.voll {
  position: absolute;
  right: 294px;
  bottom: -107px;
}

.marginTopCon {
  margin-top: 400px;
}

.w140 {
  width: 140px;
}

.disFlex {
  display: flex;
}

.w91 {
  width: 91px;
}

.bottomBox2 {
  position: relative;
  left: 155px;
  top: 337px;
}

.right385 {
  right: 385px;
}

.right424 {
  right: 424px;
}

.right270 {
  right: 270px;
}

.marginTop550 {
  margin-top: 550px;
}

.w83 {
  width: 83px;
}

.fullWidthContainer {
  width: 100%;
}

.col4 {
  width: 16%;
  float: left;
}

.col8 {
  width: 100%;
  margin-left: 10px;
}

.menuSide {
  /* width: 100%; */
  height: 100vh;
  background: #313c52;
  position: fixed;
  top: 0;
}

.menuHeader {
  background: #546b7d;
  padding: 15px;
  color: #fff;
  font-size: 20px;
}

.menuHeader i {
  margin-right: 15px;
}
.sideMenuList ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.sideMenuList ul li {
  margin: 0px;
  padding: 12px 30px;
  text-indent: 0px;
  list-style: none;
  color: #758bba;
  transition: 0.5s;
}

.sideMenuList ul li a {
  color: #758bba;
  font-size: 14px;
  text-decoration: none;
  transition: 0.5s;
}

.sideMenuList ul li:hover {
  background: #38445b;
  color: #f9f9f9;
  transition: 0.5s;
  cursor: pointer;
}

.sideMenuList ul li:hover a {
  color: #f9f9f9;
  transition: 0.5s;
}
.section-heading {
  // width:fit-content
 
  min-width:300px;
  max-width: 500px;
  width: max-content;
  padding: 9px 20px;
  background-color: #3c4b64;
  height: 60px;
  border-radius: 12px;
  margin: 15px 25px;
}
.section-heading-text {
  color: #fd6262 !important;
}

.custom-select {
  background-color: #fff !important;
  background-image: none !important;
}

.modal-header {
  background-color: #3c4b64;
  color: #fff;
}
.modal-body {
  // background-color: #636f83;
}
.form-row {
  // max-height: 55px;
}
.item-option-edit {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.fa-pencil-square-o:hover {
  color: #00bcd4;
}
.fa-trash:hover {
  color: #ed0000;
}
.input-text-area {
  align-items: center;
  display: grid;
}
.input-textArea {
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;

  border: 1px solid;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
  color: #768192;
  border-color: #d8dbe0;
}
.input-textArea:focus {
  outline: none !important;
  border-color: #719ece;
}

.bg-grey{
  background: #eeeeee !important;
}
.bg-white{
  background: #fff;
  border-radius: 8px;
}
.border6{
  border-radius: 6px;
  border-color:#295485 !important ;
}
.col-md-44{
    flex: 0 0 33.33333%;
    max-width: 31.33333%;
    // margin: 5px 10px 0 10px;   
    margin: 10px;   
}

.box-shadow2 {
  box-shadow: 0 4px 8px 0 ;
}

.fa-icon {
  font-size: 30px;
}

.canvasjs-chart-toolbar > button{
  margin-top:-5px!important
}

.empty-page{
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
}
.bg-disabled{
  background-color: #d2d2d2!important;
}

.a {
  color: #295485;           /* Default link color */
  text-decoration: underline; /* Default text decoration for links */
  cursor: pointer;        /* Default cursor for links */
}

.a:hover {
  color: blue;         /* Default link color on hover */
  text-decoration: underline; /* Default text decoration for links on hover */
}

.main-section {
    padding: rem(20) rem(25);
    height: calc(100vh - rem(64));
    overflow-y: auto;
}



.ant-menu-inline {
    border-right: 0;
}

.ant-menu-root .ant-menu-item {
    min-height: rem(54);
    margin-top: rem(8);
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon+span {
    vertical-align: top;
    line-height: rem(55);
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon {
    font-size: rem(22);
    line-height: rem(55);
    min-width: rem(30);
    text-align: center;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item {
    padding: 0 calc(50% - 32px / 2);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
    border-color: #fff;
}

.ant-menu-item {
    color: $black-600;
    border-left: rem(5) solid;
    border-color: transparent;

    a {
        color: #000;
    }

    &:hover,
    &:active,
    a:hover {
        background-color: #fff;
    }

    &:hover {
        border-color: $color-primary;

        i {
            color: #25A96D;
        }
    }
}

.ant-menu-item a:hover,
.ant-menu-item-selected,
.ant-menu-item-active {
    color: #25A96D;
}

.ant-menu-item-selected {

    a,
    a:hover {
        color: #25A96D;
        background-color: transparent;
    }
}

.ant-menu-light {
    .ant-menu-item-active {
        color: #25A96D;
    }
}

.ant-menu-inline .ant-menu-item::after {
    content: none;
}

.ant-layout-sider-trigger {
    background-color: #fff;
    text-align: left;
    padding-right: rem(16);
    padding-left: rem(24);
    transition: all 0s;

    @media screen and (min-width: 768px) {
        box-shadow: 0px 1px 0px #e9ebf8;
        border-top: 1px solid #EAEBF2;

        &:after {
            font-size: rem(12);
            content: "Collapse";
            margin-left: 12;
            color: #555A67;
        }
    }

    @media screen and (max-width: 767.98px) {
        width: rem(65) !important;
        background-color: transparent;
        top: rem(8);
        height: rem(47);
        border-top: none;
        bottom: auto;
    }
}

.icon-trigger:before {
    font-family: 'icomoon';
    content: "\e929";
    color: black;
    line-height: rem(48);
}

.ant-layout-sider-collapsed .icon-trigger:before {
    content: "\e92a";
}

.ant-layout-sider-collapsed .ant-layout-sider-trigger {
    text-align: center;
    padding: 0;

    &:after {
        content: '';
        margin-left: 0;
    }
}

.main-container {
    @media screen and (max-width: 991.98px) {
        padding-top: calc(rem(60) + rem(1));
    }
}


