// Variable overrides
// Here you can add other styles
@use "sass:math";

$html-font-size: 16px;

@function stripUnit($value) {
    @return math.div($value, $value * 0 + 1);
}

@function rem($pxValue) {
    @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

// Variable overrides
// _typography_variable
$font-family: 'Rubik', 'Helvetica Neue', sans-serif;

// _typography_weight_variables
$font-weight-normal: 400;
$font-weight-bold: 500;

// _typography_sizing_variables
$base-font-size: 0.875rem; //equivalent 14px
$h1-font-size: 1.125; //equivalent 18px

// _color_variables
$white: #FFFFFF;
$black: #000000;

$color-primary: #1F9A63;
$color-head-primary: #3B3C3F;
$color-btn-primary: #1B1C1E;
$color-links-primary: #47A86F;
$color-primary-100: #C1E4D1;
$color-secondary-900: #EF7424;

$black-1000: #1a1a1a;
$black-900: #1B1C1E;
$black-800: #3B3C3F;
$black-700: #5A5B5D;
$black-600: #6E6F71;
$black-500: #2E2C2D;
$black-400: #1D252D;
$color-red: #EF2424;
$color-red-error: #ff4d4f;
$success-green: #3c763d;
$failed-red: #a94442;
$refer-color: #4287FF;
$sold-color: #EF7424;
$active-color: #34C188;

$color-secondary: #F8F9FC;
$white-100: #E9EAED;
$white-200: #F9FAFF;
$white-300: #D9DADD;
$white-400: #F6F9FC;
$white-500: #D0D0D0;
$white-600: #DDE5ED;
$white-700: #FEF1F1;
$white-800: #F5F7FB;
$white-900: #F5F5F5;
$white-1000: #EFF4FA;
$white-1100: #96979A;
$white-1200: #F3F3F3;
$info-blue: #A7C7E7;
$info-blue-notify: #91d5ff;
$breeze: #CCF5E2;
$disabled-border: #d9d9d9;
$disabled-bg: #f5f5f5;
$card-bg: #F2F3F6;
$card-border: #EAEBF2;
$icon-bg: #b6b7b9;
$scroll-track: #E5E4E2;
$scroll-thumb: #A9A9A9;
$note-border: #D1FCE7;
$dnd-border: #B6B7B9;
$tag-bg: #eeeded;



// _spacing_variables
$base-padding: 5px;
$base-margin: 5px;

$small-padding: $base-padding * 2;
$small-margin: $base-margin * 2;

$medium-padding: $base-padding * 3;
$medium-margin: $base-margin * 3;

$large-padding: $base-margin * 4;
$large-margin: $base-margin * 4;

$xlarge-padding: $base-margin * 5;
$xlarge-margin: $base-margin * 5;

//Transition

@mixin transition($transition) {
    -moz-transition: all ease $transition;
    -o-transition: all ease $transition;
    -webkit-transition: all ease $transition;
    transition: all ease $transition;
}